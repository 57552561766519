import React from 'react';
import { Script } from 'gatsby';

const Binotel = ({ widgetHash }) => {
  return (
    <Script
      src={`https://widgets.binotel.com/getcall/widgets/${widgetHash}.js`}
    />
  );
};

export default Binotel;
