import React from 'react';
import Binotel from './Binotel';
import Hotjar from './Hotjar';

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <Binotel widgetHash='7tpncinyu1oorlybo5g0' />
      <Hotjar hotjarId={3392759} />
    </>
  );
};
